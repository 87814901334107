import React from 'react';
import './Overview.css';  // Link to the CSS file
import img from '../assets/img/body.png'

const Overview = () => {
    return (
        <div className="overview-container">
            <div className="overview-header">
                <h2 className="section-overview-title">Overview</h2>
            </div>
            <div className="overview-content">

                <p className="overview-subtitle">
                    <i>Excavate Research is a leader in Market Research and Business Intelligence.</i>
                </p>
                <p className="overview-paragraph">
                    In early 2010, a group of experienced market research professionals came together to pursue their passion for understanding the mind of the consumer. This is when Excavate Research was born, and today, it is one of the most innovative market research companies in India.
                </p>

                <div className="banner11">
                    <img src={img} loading="lazy"  />

                    <div id="ourCore">
                        <h3 className="section-overview-title">Our Core Services</h3>
                        <ul className="overview-services-list">
                            <li>Survey Programming</li>
                            <li>Data Collection</li>
                            <li>Open End Coding</li>
                            <li>Data Tabulation</li>
                            <li>Data Analysis</li>
                            <li>Online/Offline Dashboards Development</li>
                            <li>Monitoring Financial KPIs via real-time dashboards</li>
                            <li>Competitor Landscaping</li>
                            <li>Business Optimization & Streamlining</li>
                            <li>Investor Pitch Creation for Fundraising</li>
                            <li>Investment Trend & Future Outlook</li>
                            <li>Financial and Operational Performance Analysis</li>
                            <li>Business Plans & Financial Modeling</li>
                        </ul>
                    </div>
                    </div>

                    {/* <div className="approach-section">
                    <h3 className="section-approach-title">Our Approach</h3>
                    <p className="approach-paragraph">
                        Our development methodologies are driven by a commitment to Agile and DevOps practices. By adopting Agile, we ensure iterative progress and continuous improvement, allowing us to respond to changes quickly. Our DevOps practices enhance the collaboration between our development and operations teams, automating repetitive tasks and ensuring faster, more reliable software delivery.
                    </p>
                </div>

                <div className="values-section">
                    <h3 className="section-values-title">Company Values</h3>
                    <p className="values-paragraph">
                        At Excavate Research, we believe in the power of collaboration, continuous improvement, and customer satisfaction. Our core values are rooted in Agile principles and DevOps practices. We emphasize a culture of open communication, shared goals, and constant feedback to deliver the best outcomes for our clients. By fostering innovation and maintaining a strong focus on our customers, we continuously strive to improve and evolve.
                    </p>
                </div> */}

                    {/* Innovations and Tools Section */}
                    {/* <div className="overview-conclusion">
                    <h3 className="section-overview-title">Innovations and Tools</h3>
                    <p className="overview-paragraph">
                        Excavate has launched Tablet Surveys, Automated PPTs, and Compliance Management tools. We offer customized development based on user requirements and consulting for Compliance Management.
                    </p>
                </div> */}

                    <div className="overview-conclusion">
                        <h3 className="section-overview-title">Innovations and Tools</h3>
                        <p className="overview-paragraph">
                            Excavate has launched Tablet Surveys, Automated PPTs, and Compliance Management tools. We offer customized development based on user requirements and consulting for Compliance Management.
                        </p>
                    </div>
                </div>
            </div>
            );
};

            export default Overview;
