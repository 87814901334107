// ContactLinks.js
import React from 'react';
import './ContactLinks.css'; // Import your custom CSS for styling
import { FaLinkedin, FaWhatsapp, FaFacebook } from 'react-icons/fa'; // Import LinkedIn and WhatsApp icons

const ContactLinks = () => {
  return (
    <div className="contact-links">
      <a
        href="https://www.linkedin.com/company/excavate-research-&-analytics/"
        target="_blank"
        rel="noopener noreferrer"
        className="contact-icon linkedin"
        aria-label="LinkedIn"
      >
        <FaLinkedin size={32} />
      </a>
      <a
        href="https://wa.me/9818329532" // Use WhatsApp number format: https://wa.me/<YourNumber>
        target="_blank"
        rel="noopener noreferrer"
        className="contact-icon whatsapp"
        aria-label="WhatsApp"
      >
        <FaWhatsapp size={32} />
      </a>
      <a
        href="https://www.facebook.com/ExcavateResearchServices?mibextid=ZbWKwL"
        target="_blank"
        rel="noopener noreferrer"
        className="contact-icon facebook"
        aria-label="Facebook"
      >
        <FaFacebook size={30} />
      </a>
    </div>
  );
};

export default ContactLinks;
