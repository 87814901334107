import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './AgileDevOpsDiagram.css';

const AgileDevOpsDiagram = () => {
  const svgRefAgile = useRef(); // Define reference for Agile SVG
  const svgRefDevOps = useRef(); // Define reference for DevOps SVG

  const nodesAgile = [
    { id: 'agile', label: 'Agile Methodology', x: 250, y: 50, color: '#f9c74f' },
    { id: 'customer', label: 'Customer Satisfaction', x: 100, y: 150, color: '#90be6d' },
    { id: 'collaboration', label: 'Collaboration', x: 400, y: 150, color: '#90be6d' },
    { id: 'iterative', label: 'Iterative Development', x: 100, y: 250, color: '#90be6d' },
    { id: 'adaptability', label: 'Adaptability', x: 400, y: 250, color: '#90be6d' },
  ];

  const linksAgile = [
    { source: 'agile', target: 'customer' },
    { source: 'agile', target: 'collaboration' },
    { source: 'agile', target: 'iterative' },
    { source: 'agile', target: 'adaptability' },
  ];
  const nodesDevOps = [
    { id: 'devops', label: 'DevOps Methodology', x: 250, y: 50, color: '#f3722c' },
    { id: 'collaborationDevOps', label: 'Collaboration', x: 100, y: 150, color: '#f9c74f' },
    { id: 'automation', label: 'Automation', x: 400, y: 150, color: '#f9c74f' },
    { id: 'ciCd', label: 'Continuous Integration/Deployment (CI/CD)', x: 250, y: 250, color: '#f9c74f' },
    { id: 'monitoring', label: 'Monitoring & Feedback', x: 250, y: 350, color: '#f9c74f' },
  ];

  const linksDevOps = [
    { source: 'devops', target: 'collaborationDevOps' },
    { source: 'devops', target: 'automation' },
    { source: 'devops', target: 'ciCd' },
    { source: 'devops', target: 'monitoring' },
  ];

  useEffect(() => {
    const createDiagram = (svgRef, nodes, links) => {
      const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 600 400`) // Set viewBox to maintain aspect ratio
      .attr('preserveAspectRatio', 'xMidYMid meet') // Maintain aspect ratio during scaling
      .style('background', '#e3f2d3') // Different background color for Agile diagram
      .classed('responsive-svg', true); // Add a class for additional styling

      const simulation = d3.forceSimulation(nodes)
        .force('link', d3.forceLink(links).id(d => d.id).distance(100))
        .force('charge', d3.forceManyBody().strength(-500))
        .force('center', d3.forceCenter(300, 200));

      const link = svg.append('g')
        .attr('class', 'links')
        .selectAll('line')
        .data(links)
        .enter().append('line')
        .attr('stroke', '#00796b')
        .attr('stroke-width', 3);

      const node = svg.append('g')
        .attr('class', 'nodes')
        .selectAll('circle')
        .data(nodes)
        .enter().append('circle')
        .attr('r', 20)
        .attr('fill', d => d.color)
        .call(d3.drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended));

      const text = svg.append('g')
        .attr('class', 'texts')
        .selectAll('text')
        .data(nodes)
        .enter().append('text')
        .attr('dy', -30)
        .attr('text-anchor', 'middle')
        .attr('font-size', 7)
        .attr('fill', '#333')
        .text(d => d.label);

      simulation
        .nodes(nodes)
        .on('tick', () => {
          link
            .attr('x1', d => d.source.x)
            .attr('y1', d => d.source.y)
            .attr('x2', d => d.target.x)
            .attr('y2', d => d.target.y);

          node
            .attr('cx', d => d.x)
            .attr('cy', d => d.y);

          text
            .attr('x', d => d.x)
            .attr('y', d => d.y);
        });

      simulation.force('link')
        .links(links);

      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }
    };

    createDiagram(svgRefAgile, nodesAgile, linksAgile);
    createDiagram(svgRefDevOps, nodesDevOps, linksDevOps);
  }, []);

  return (
    <div className="diagram-container">
      <h2 className='section-title1' id='h2'>Agile and DevOps</h2>
      <p id="p">
        <strong>Focus:</strong> Agile focuses on software development and delivery practices, while DevOps emphasizes collaboration and automation between development and IT operations.<br />
        <strong>Goals:</strong> Agile aims to improve the process of software development, while DevOps aims to enhance the entire software lifecycle from development to operations.
      </p>
      <div className="info-AgileDevOps-section">
    <div className="text-content">
      <h2>Agile Methodology</h2>
      <p id="p">
        <strong>Definition:</strong> Agile is a project management and product development approach that emphasizes flexibility, collaboration, and customer feedback.
      </p>
      <h3 id="h3">Key Principles:</h3>
      <ul>
        <li><strong>Customer Satisfaction:</strong> Deliver valuable software early and continuously to enhance customer satisfaction.</li>
        <li><strong>Collaboration:</strong> Foster collaboration between business stakeholders and developers throughout the project.</li>
        <li><strong>Iterative Development:</strong> Break projects into small, manageable units called iterations or sprints, allowing teams to adjust based on feedback.</li>
        <li><strong>Adaptability:</strong> Embrace changing requirements, even late in the development process, to improve the product.</li>
      </ul>
      <h3 id="h3">Agile Frameworks:</h3>
      <ul>
        <li><strong>Scrum:</strong> Focuses on iterative progress through defined roles (Scrum Master, Product Owner, Development Team), artifacts (Product Backlog, Sprint Backlog), and ceremonies (Sprint Planning, Daily Standup, Sprint Review).</li>
        <li><strong>Kanban:</strong> Visualizes the workflow and limits work in progress to enhance efficiency and delivery flow.</li>
      </ul>
      <h3 id="h3">Benefits:</h3>
      <ul>
        <li>Increased flexibility and adaptability.</li>
        <li>Enhanced team collaboration and communication.</li>
        <li>Faster time to market with continuous delivery.</li>
      </ul>
      <div className="agile-diagram-container">
        {/* <h2>Agile Diagram</h2> */}
        <svg ref={svgRefAgile}></svg>
      </div>

      <h2>DevOps Practices</h2>
      <p id="p">
        <strong>Definition:</strong> DevOps is a cultural and technical movement aimed at integrating and automating the work of software development (Dev) and IT operations (Ops) to improve collaboration and productivity.
      </p>
      <h3 id="h3">Key Principles:</h3>
      <ul>
        <li><strong>Collaboration:</strong> Break down silos between development and operations teams to foster a collaborative culture.</li>
        <li><strong>Automation:</strong> Automate repetitive tasks (e.g., testing, deployment) to improve efficiency and reduce human error.</li>
        <li><strong>Continuous Integration/Continuous Deployment (CI/CD):</strong> Implement CI/CD pipelines to enable frequent integration of code changes and automatic deployment to production environments.</li>
        <li><strong>Monitoring and Feedback:</strong> Continuously monitor systems in real-time to gain insights and improve future development cycles.</li>
      </ul>
      <h3 id="h3">DevOps Practices:</h3>
      <ul>
        <li><strong>Infrastructure as Code (IaC):</strong> Manage infrastructure through code to automate deployment and configuration.</li>
        <li><strong>Microservices:</strong> Architect applications as a collection of loosely coupled services for better scalability and maintainability.</li>
        <li><strong>Version Control:</strong> Use version control systems (like Git) for collaboration and tracking changes in code.</li>
      </ul>
      <h3 id="h3">Benefits:</h3>
      <ul>
        <li>Improved deployment frequency and faster time to market.</li>
        <li>Enhanced reliability and stability of applications.</li>
        <li>Increased efficiency through automation and streamlined processes.</li>
      </ul>
      
      <div className="devops-diagram-container">
        {/* <h2>DevOps Diagram</h2> */}
        <svg ref={svgRefDevOps}></svg>
      </div>
    </div>
  </div>
    </div>
  );
};

export default AgileDevOpsDiagram;
