import React, { useState } from 'react';
import './ContactUsDetailed.css'; // Import the CSS file for this component
import { Link } from 'react-router-dom'; // For navigation links
import logo from '../assets/img/logo-small.png'; // Import the logo image
import careersImage from '../assets/img/careers1.jpg';
import axios from 'axios';
import config from '../config'; // Adjust the import path as necessary
import Headers from './Header';
import { Card, CardHeader } from 'reactstrap';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'; // Import reactstrap components

const ContactUsDetailed = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    subject: '',
    interest: '',
    cvFile: '',
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [mapSrc, setMapSrc] = useState(
    'https://www.google.com/maps/embed/v1/place?key=AIzaSyDkojOC2iB9nnyosKQpt4mj_O-88IePqec&q=Netaji+Subhash+Place+Pitampura+New+Delhi+Delhi+110034,CA'
  );
  // const [cvFile, setCvFile] = useState(null);

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      cvFile: e.target.files[0]
    });
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('first_name', formData.first_name);
      formDataToSend.append('last_name', formData.last_name);
      formDataToSend.append('company_name', formData.company_name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('subject', formData.subject);
      formDataToSend.append('interest', formData.interest);
      formDataToSend.append('cv', formData.cvFile); // Append the CV file

      const response = await axios.post(
        `${config.API_BASE_URL}/submit-contact`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        alert('Thank you for your interest in a career with us!');
        // Clear form fields
        setFormData({
          first_name: '',
          last_name: '',
          company_name: '',
          email: '',
          subject: '',
          interest: '',
          cvFile: '',
        });
      } else {
        alert('Error submitting form');
      }
    } catch (error) {
      alert('Error submitting form');
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevents page reload

    if (searchQuery.trim()) {
      const encodedQuery = encodeURIComponent(searchQuery);
      const googleMapsBase = 'https://www.google.com/maps/embed/v1/place';
      const apiKey = 'AIzaSyDkojOC2iB9nnyosKQpt4mj_O-88IePqec'; // Insert your actual API key

      setMapSrc(`${googleMapsBase}?key=${apiKey}&q=${encodedQuery}`);
    } else {
      alert('Please enter a valid search query.');
    }
  };

  return (
    <div className="contact-us-detailed">

      <div className="contact-image-container">
        <img src={careersImage} alt="Contact Us" className="contact-image" loading="lazy"  />
        <div className="contact-image-text">
          <h2>Career</h2>
          <p>Join our team and make a difference.</p>
        </div>
      </div>

      {/* <div className="section">
        <h3>Our Work Culture</h3>
        <p>
          At our company, we embrace a collaborative, dynamic environment that fosters innovation and creativity. Agile and DevOps practices are the core of our work culture, enabling us to deliver high-quality solutions while responding to client needs with speed and efficiency. Cross-functional teams work closely to ensure continuous improvement and a shared sense of ownership over our projects.
        </p>
      </div>

      <div className="section">
        <h3>Job Descriptions</h3>
        <p>
          We are always looking for talented individuals with skills in Agile methodologies and DevOps practices. Whether you're a developer, operations expert, or project manager, experience with Agile frameworks and continuous delivery pipelines is highly valued in our roles. Explore our current job openings to see how you can contribute to our mission.
        </p>
      </div> */}


      <div className="contact-content"> {/* Form and Map inside the same div */}
        <div>
          <Form onSubmit={handleSubmit}>
            <h3>Let's Work Together</h3>
            <FormGroup>
              <Label for="first-name">First Name*</Label>
              <Input
                type="text"
                id="first-name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="last-name">Last Name*</Label>
              <Input
                type="text"
                id="last-name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="company-name">Company Name*</Label>
              <Input
                type="text"
                id="company-name"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email*</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="subject">Subject*</Label>
              <Input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="interest">What are you interested in?</Label>
              <Input
                type="textarea"
                id="interest"
                name="interest"
                value={formData.interest}
                onChange={handleChange}
                placeholder="Let us know what you're interested in"
              />
            </FormGroup>
            <FormGroup>
              <Label for="cv">Upload CV*</Label>
              <Input
                type="file"
                id="cv"
                name="cv"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Button type="submit" color="primary">Submit</Button>
            </FormGroup>
          </Form>
        </div>

        <div className="map">
          <div>
            <h3>Map</h3>
            <div className="search-map">
              <form className='map-form' onSubmit={handleSearchSubmit}>
                {/* <input
                  type="text"
                  placeholder="Search location..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                /> */}
                {/* <button type="submit" className='btn btn-primary'>Search</button> */}
              </form>
            </div>

          </div>
          <iframe
            src={mapSrc}
            width="100%"
            height="500px"
            frameBorder="0"
            allowFullScreen
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUsDetailed;
