// src/components/Footer.js
import React, { useState } from 'react';
import './Footer.css';
import CookieConsentPopup from './CookieConsentPopup'; // Import CookieConsentPopup component
import ContactLinks from './ContactLinks';

const Footer = () => {
  const [cookieConsentVisible, setCookieConsentVisible] = useState(true);

  const handleAcceptCookies = () => {
    setCookieConsentVisible(false); // Hide the popup when accepted
    // Add any additional logic to save the user's consent (e.g., localStorage)
  };

  const handleDenyCookies = () => {
    setCookieConsentVisible(false); // Hide the popup when denied
    // Add any logic for denying cookies if needed
  };

  const handleManagePreferences = () => {
    // Open a preferences dialog or redirect the user to manage cookies
    alert('Manage your cookie preferences here!');
  };

  return (
    <footer>
      <CookieConsentPopup 
        show={cookieConsentVisible} 
        onAccept={handleAcceptCookies} 
        onDeny={handleDenyCookies} 
        onManage={handleManagePreferences} 
      />
      <p>&copy; {new Date().getFullYear()} Excavate Research & Analysis Pvt. Ltd.</p>
      <ContactLinks />
    </footer>
  );
}

export default Footer;
