import React from 'react';
import './Home.css';
import AI from '../assets/img/HI-and-AI.png';
import Mission from '../components/Mission';
import vid from '../assets/vid/HomeScreen.mp4';
import { useState } from 'react';

const Home = () => {
  // const [showIntro, setShowIntro] = useState(false);
  const [showIntro, setShowIntro] = useState(true);

  const handleHideIntro = () => {
    setShowIntro(false);
  };
  return (
    <div className="home-section">
      <div className="hero-section">
        <video className="hero-video" autoPlay muted loop playsInline>
          <source src={vid} type="video/mp4" />
        </video>
        <div className="hero-content">
          <h1 className="hero-title">Empower Your Business with AI-Driven Insights</h1>
          <p className="hero-description">
            Discover the future of market research with intelligent data analytics that drives innovation and decision-making.
          </p>
        </div>
      </div>
      <Mission />
      <div className="services-section">
        <h1 className="section-title">Our AI-Powered Services</h1>
        <div className="services-grid">
          <div className="card">
            <div className="card-content">
            <h3 className="card-title">Predictive Analytics</h3>
            <p className="card-para">Gain deep insights into market trends and consumer behavior using advanced predictive models.</p>
            </div>
          </div>
          <div className="card">
          <div className="card-content">
            <h3 className="card-title">Data Mining &amp; AI</h3>
            <p className="card-para">Unlock the power of data to unearth actionable intelligence that gives you a competitive edge.</p>
          </div>
          </div>
          <div className="card">
          <div className="card-content">
            <h3 className="card-title">Automation Solutions</h3>
            <p className="card-para">Streamline your processes and enhance productivity with AI-powered automation solutions.</p>
          </div>
          </div>
          <div className="card">
          <div className="card-content">
            <h3 className="card-title">Market Research & Consulting</h3>
            <p className="card-para">Leverage our expertise in market research and consulting to make informed strategic decisions and gain a deeper understanding of your industry.</p>
          </div>
          </div>
        </div>
      </div>
      {/* New Section for AI content and image */}
      <div className="ai-home-section">
        <div className="ai-home-content">
          <h2>Revolutionizing Industries with AI</h2>
          <p>
            Artificial Intelligence is transforming the way businesses operate by automating complex tasks, analyzing vast amounts of data, and providing insights that were previously unattainable. Our AI solutions empower companies to innovate faster, optimize operations, and stay ahead in a rapidly evolving digital world.
          </p>
          <p>
            Whether it's predictive analytics, automation, or deep learning, AI is redefining industries by unlocking new potentials in every field.
          </p>
        </div>
        <div className="ai-home-image">
          <img src={AI} alt="AI Revolution" loading="lazy"  />
        </div>
      </div>
      {/* <div className="tagline-banner">
      <h1 className="section-title">Empowering Innovation with Agile and DevOps</h1>
      <p><i>Transforming businesses with cutting-edge technology and innovative solutions.</i></p>
        <div className="ai-home-content">
          <h2>Our Commitment to Agile and DevOps</h2>
          <p>
            At our company, we are dedicated to delivering high-quality solutions that meet the evolving needs of our clients. We believe in the power of Agile and DevOps to drive innovation, improve efficiency, and reduce costs.
          </p>
        </div>
    </div> */}
    </div>
  );
}

export default Home;
