import React, { useState } from 'react';
import './Scripting.css';
import scriptingImage from '../assets/img/scripting_image.png'; 
import featureImage1 from '../assets/img/Real-Time Customization.png'; 
import featureImage2 from '../assets/img/Multi-Language Support.png';
import featureImage3 from '../assets/img/Complex Logic and Branching.jpg';

const Scripting = () => {
    const [showMore, setShowMore] = useState(false);

    const toggleLearnMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div className="scripting-container">
            <div className="scripting-content">
                <h2 className="section-title-script animate-fade-in">Advanced Survey Scripting</h2>
                <p className="section-text-script animate-fade-in">
                    Our custom survey scripting service ensures that your surveys are tailored to meet unique business goals. We utilize advanced scripting techniques to create dynamic, engaging, and highly interactive surveys, designed for any industry.
                </p>

                <div className="scripting-main">
                    <img src={scriptingImage} alt="Scripting Process" className="section-image-script animate-fade-in" loading="lazy"  />
                    <div className="scripting-details">
                        <h3 className="scripting-subtitle animate-fade-in">Key Features</h3>
                        <p className="scripting-description animate-fade-in">
                            Take full advantage of our cutting-edge scripting features that enhance the survey experience, making it engaging and insightful for both respondents and data collectors.
                        </p>
                        <ul className="scripting-list">
                            <li className="feature-item animate-slide-in">
                                <img src={featureImage1} alt="Customization Icon" className="feature-icon" loading="lazy"  />
                                <strong>Real-Time Customization:</strong> Surveys dynamically adapt to user inputs, ensuring relevance and smooth user experience.
                            </li>
                            <li className="feature-item animate-slide-in">
                                <img src={featureImage2} alt="Multi-Language Icon" className="feature-icon" loading="lazy"  />
                                <strong>Multi-Language Support:</strong> Reach a global audience by offering surveys in multiple languages.
                            </li>
                            <li className="feature-item animate-slide-in">
                                <img src={featureImage3} alt="Logic and Branching Icon" className="feature-icon" loading="lazy"  />
                                <strong>Complex Logic and Branching:</strong> Personalize user journeys with sophisticated logic and conditional branching.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="scripting-intro">
                    <h3 className="scripting-intro-title">How Survey Scripting Works</h3>
                    <p>
                        At Excavate Research, we specialize in creating advanced survey scripts tailored to meet your research needs. Leveraging AI and machine learning, we automate complex survey logic and routing, ensuring error-free scripts that adapt to respondent behaviors in real-time. Our platform supports integration with multiple survey systems like SPSS Dimensions, Decipher, and Confirmit.
                    </p>
                    <button className="learn-more-button" onClick={toggleLearnMore}>
                        {showMore ? 'Show Less' : 'Learn More'}
                    </button>
                </div>

                {showMore && (
                    <div className="learn-more-content">
                        <h4>Survey Scripting: Expanded Explanation</h4>
                        <p>
                            Excavate Research leverages AI and automation to streamline the entire survey scripting process. Our system uses machine learning algorithms to automatically adapt the script based on predefined logic, simplifying complex survey flows and routing for diverse audiences.
                        </p>
                        <p>
                            With integrations across platforms like SPSS Dimensions, Decipher, and Confirmit, we ensure seamless deployment, while AI minimizes human error and speeds up the process.
                        </p>
                        <h5>Case Study</h5>
                        <p>
                            A global market research company needed to script a multi-lingual survey for a product launch, including complex routing logic based on demographics and behavior. By using our AI-driven survey scripting solution, the company was able to cut their scripting time by 50%. AI helped automate the translation of routing logic across 12 languages, ensuring consistency and accuracy across all markets. This reduced their operational costs by 40% and accelerated their survey deployment.
                        </p>
                    </div>
                )}

                <div className="feature-grid animate-fade-in">
                    <div className="feature-card">
                        <img src={featureImage1} alt="Survey Randomization" className="feature-image" loading="lazy"  />
                        <h4>Survey Randomization</h4>
                        <p>Randomize questions or choices to reduce bias and collect accurate insights.</p>
                    </div>
                    <div className="feature-card">
                        <img src={featureImage2} alt="Mobile Optimization" className="feature-image" loading="lazy"  />
                        <h4>Mobile-Optimized Design</h4>
                        <p>Ensure your surveys work flawlessly on all devices, especially mobile.</p>
                    </div>
                    <div className="feature-card">
                        <img src={featureImage3} alt="Analytics" className="feature-image" loading="lazy"  />
                        <h4>Real-Time Analytics</h4>
                        <p>Monitor responses and extract actionable data insights in real time.</p>
                    </div>
                </div>

                <div className="scripting-footer">
                    <h3 className="scripting-footer-title">Why Choose Us for Survey Scripting?</h3>
                    <p className="scripting-footer-text">
                        <strong>AI-Driven Automation:</strong> Use AI to automate question logic, ensuring faster survey deployment.<br />
                        <strong>Seamless Integration:</strong> Compatible with all major survey platforms.<br />
                        <strong>Cost-Effective Solutions:</strong> Reducing manual scripting efforts through intelligent automation, lowering your overall costs.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Scripting;
