import React, { useState, useEffect } from 'react';
import './CookiePreferencesModal.css';

const CookiePreferencesModal = ({ isVisible, onClose, onSavePreferences }) => {
    const [analyticsCookie, setAnalyticsCookie] = useState(false);
    const [marketingCookie, setMarketingCookie] = useState(false);

    useEffect(() => {
        if (isVisible) {
            const analytics = localStorage.getItem('analytics-cookie') === 'true';
            const marketing = localStorage.getItem('marketing-cookie') === 'true';
            setAnalyticsCookie(analytics);
            setMarketingCookie(marketing);
        }
    }, [isVisible]);

    const handleSavePreferences = () => {
        localStorage.setItem('analytics-cookie', analyticsCookie);
        localStorage.setItem('marketing-cookie', marketingCookie);
        onSavePreferences(); // Close both modals when saving preferences
    };

    const handleRejectAll = () => {
        setAnalyticsCookie(false);
        setMarketingCookie(false);
        localStorage.setItem('analytics-cookie', 'false');
        localStorage.setItem('marketing-cookie', 'false');
        onSavePreferences(); // Close both modals when rejecting all
    };

    const handleClose = () => {
        onClose(); // Close the modal when clicking the close button
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-modal-overlay">
            <div className="cookie-modal">
                <button className="close-btn" onClick={handleClose}>&#x2716;</button> {/* Close button with a cross */}
                <h2>Manage Cookie Preferences</h2>

                <div className="form-group">
                    <label className="switch-label">
                        <h6>Necessary</h6>
                        <span className="always-active">Always Active</span>
                    </label>
                </div>
                <p className="cookie-description">
                    Necessary Cookies are required for the basic functionality of the website, such as ensuring that pages load properly. These cookies cannot be disabled.
                </p>

                <div className="form-group">
                    <label className="switch-label">
                        <h6>Analytics</h6> 
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={analyticsCookie}
                                onChange={(e) => setAnalyticsCookie(e.target.checked)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </label>
                </div>
                <p className="cookie-description">
                    Analytics Cookies help us understand how visitors use our website, allowing us to improve user experience and website performance. These cookies collect anonymous data and do not store personal information.
                </p>

                <div className="form-group">
                    <label className="switch-label">
                        <h6>Marketing</h6>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={marketingCookie}
                                onChange={(e) => setMarketingCookie(e.target.checked)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </label>
                </div>
                <p className="cookie-description">
                    Marketing Cookies track your browsing behavior to show personalized ads. This helps us deliver relevant advertisements, and you can manage your preferences at any time.
                </p>

                <div className="modal-buttons">
                    <button type="button" className="btn-save" onClick={handleSavePreferences}>
                        Save My Preferences
                    </button>
                    <button type="button" className="close" id='close' style={{ height: 'auto' }} onClick={handleRejectAll}>
                        Reject All
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookiePreferencesModal;
