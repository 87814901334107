import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css'; // Import the CSS file
import logo from '../assets/img/logo-small.png'; // Import the logo image

const Header = () => {
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isCloudComputingOpen, setIsCloudComputingOpen] = useState(false);
  const [isMarketResearchOpen, setIsMarketResearchOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'nav-link active' : 'nav-link';
  };

  const getDropdownLinkClass = (path) => {
    return location.pathname.startsWith(path) ? 'nav-link active' : 'nav-link';
  };

  useEffect(() => {
    if (!location.pathname.startsWith('/services/cloud-computing')) {
      setIsCloudComputingOpen(false);
    }
    if (!location.pathname.startsWith('/services/market-research')) {
      setIsMarketResearchOpen(false);
    }
  }, [location.pathname]);

  // Close the navbar and dropdowns on link click
  const handleNavClick = () => {
    window.scrollTo(0, 0);
    setIsNavOpen(false);
    setIsServicesOpen(false);
    setIsCloudComputingOpen(false);
    setIsMarketResearchOpen(false); 
  };

  return (
    <nav className="navbar" style={{ position: 'fixed' }}>
      <div className="navbar-container">
        <img src={logo} alt="Company Logo" className="center-logo" loading="lazy"  />

        <button className="navbar-toggle" onClick={() => setIsNavOpen(!isNavOpen)}>
          &#9776;
        </button>

        <div className={`navbar-content ${isNavOpen ? 'show' : ''}`}>
          <Link to="/" className={getNavLinkClass('/')} onClick={handleNavClick}>
            Home
          </Link>

          <div className="nav-dropdown" onMouseOver={() => setIsNavOpen(true)} onMouseOut={() => setIsNavOpen(false)}>
            <span className={getDropdownLinkClass('/about')}>
              About
            </span>
            <div className="dropdown-menu">
              <Link to="/about/overview" className="dropdown-item" onClick={handleNavClick}>
                Overview
              </Link>
              <Link to="/about/fun-of-era" className="dropdown-item" onClick={handleNavClick}>
                Meet Our Team
              </Link>
              <Link to="/about/csr-initiative" className="dropdown-item" onClick={handleNavClick}>
                CSR Initiative
              </Link>
            </div>
          </div>

          <div className="nav-dropdown" onMouseOver={() => setIsServicesOpen(true)} onMouseOut={() => setIsServicesOpen(false)}>
            <span className={getDropdownLinkClass('/services')}>
              Services
            </span>
            {isServicesOpen && (
              <div className="dropdown-menu">
                <div className="nav-dropdown" onMouseOver={() => setIsCloudComputingOpen(true)} onMouseOut={() => setIsCloudComputingOpen(false)}>
                  <span
                    className={getDropdownLinkClass('/services/cloud-computing')}
                  >
                    Cloud Computing
                  </span>
                  {isCloudComputingOpen && (
                    <div className="dropdown-menu dropdown-left">
                      <Link to="/services/cloud-computing/data-extraction" className="dropdown-item" onClick={handleNavClick}>
                        Data Extraction
                      </Link>
                      <Link to="/services/cloud-computing/pipeline-development" className="dropdown-item" onClick={handleNavClick}>
                        Pipeline Development
                      </Link>
                      <Link to="/services/cloud-computing/data-warehouse" className="dropdown-item" onClick={handleNavClick}>
                        Data Warehouse Design
                      </Link>
                      <Link to="/services/cloud-computing/data-integration" className="dropdown-item" onClick={handleNavClick}>
                        Data Integration
                      </Link>
                      <Link to="/services/cloud-computing/data-governance" className="dropdown-item" onClick={handleNavClick}>
                        Data Governance
                      </Link>
                      <Link to="/services/cloud-computing/cloud-migration" className="dropdown-item" onClick={handleNavClick}>
                        Cloud Data Migration
                      </Link>
                    </div>
                  )}
                </div>

                <Link to="/services/data-analysis" className="dropdown-item" onClick={handleNavClick}>
                  Data Analysis
                </Link>
                <Link to="/services/charting" className="dropdown-item" onClick={handleNavClick}>
                  Charting
                </Link>
                <Link to="/services/data-visualization" className="dropdown-item" onClick={handleNavClick}>
                  Data Visualization
                </Link>
                <Link to="/services/scripting" className="dropdown-item" onClick={handleNavClick}>
                  Survey Scripting
                </Link>
                {/* New Market Research dropdown */}
                {/* <div className="nav-dropdown" onMouseOver={() => setIsMarketResearchOpen(true)} onMouseOut={() => setIsMarketResearchOpen(false)}>
                  <span className={getDropdownLinkClass('/services/market-research')}>
                    Market Research
                  </span>
                  {isMarketResearchOpen && (
                    <div className="dropdown-menu dropdown-left">
                      <Link to="/services/market-research/survey-scripting" className="dropdown-item" onClick={handleNavClick}>
                        Survey Scripting
                      </Link>
                      <Link to="/services/market-research/data-processing" className="dropdown-item" onClick={handleNavClick}>
                        Data Processing
                      </Link>
                    </div>
                  )}
                </div> */}
                <Link to="/services/consulting" className="dropdown-item" onClick={handleNavClick}>
                  Consulting
                </Link>
                <Link to="/services/ai" className="dropdown-item" onClick={handleNavClick}>
                  Artificial Intelligence
                </Link>
                <Link to="/services/software-development-expertise" className="dropdown-item" onClick={handleNavClick}>
                  Software Development Expertise
                </Link>
              </div>
            )}
          </div>
          <Link to="/contact" className={getNavLinkClass('/contact')} onClick={handleNavClick}>
            Contact
          </Link>
          <Link to="/career" className={getNavLinkClass('/career')} onClick={handleNavClick}>
            Career
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
