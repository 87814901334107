import React from 'react';
import { Link } from 'react-router-dom';
import dataAnalystImage from '../assets/img/data_analyst_image.png';
import chartingImage from '../assets/img/charting_image.png';
import dashboardsImage from '../assets/img/dashboards_image.png';
import scriptingImage from '../assets/img/scripting_image.png';
import consultingImage from '../assets/img/consulting.png';
import aiImage from '../assets/img/HI-and-AI.png';
import './Services.css'; // Import CSS for Services component
import { Button, Container, Row, Col, Card, CardTitle,CardText, CardImg, CardHeader } from 'reactstrap';
import Slideshow from './Slideshow';
import Home from './Home';

const Services = () => {
  return (
    <section id="services">
      <Slideshow />
      <Container>
        <div
            color="success"
            outline 
         className="text-center">
        <h3>
      Service Offering
    </h3>
        <ul>
          {[
            {
              name: 'Data Analysis',
              description:
                // 'Our skilled data analysts delve deep into your datasets, uncovering patterns, trends, and correlations...',
              'Our skilled data analysts delve deep into your datasets, uncovering patterns, trends, and correlations that might otherwise go unnoticed. By employing advanced analytical techniques, we extract meaningful insights to support your business objectives.',
              image: dataAnalystImage,
              link: '/services/data-analysis',
            },
            {
              name: 'Charting',
              description:
                // 'We understand the power of visual communication. Utilizing cutting-edge tools and expertise...',
              'We understand the power of visual communication. Utilizing cutting-edge tools and expertise, we transform raw data into visually appealing charts and graphs. These dynamic visualizations not only enhance comprehension but also facilitate effective communication of your data-driven narratives.',
              image: chartingImage,
              link: '/services/charting',
            },
            {
              name: 'Dashboards',
              description:
                // 'Gain real-time visibility into your key metrics and trends with our customized dashboards...',
              'Gain real-time visibility into your key metrics and trends with our customized dashboards. Designed to meet your specific requirements, these dashboards provide a consolidated view of pertinent information, enabling quick decision-making and proactive management of your operations.',
              image: dashboardsImage,
              link: '/services/dashboards',
            },
            {
              name: 'Scripting',
              description:
                // 'Streamline your processes and boost productivity with our scripting solutions...',
                'Streamline your processes and boost productivity with our scripting solutions. Our adept team develops tailored scripts to automate repetitive tasks, reducing manual intervention and enhancing operational efficiency across your organization.',
              image: scriptingImage,
              link: '/services/scripting',
            },
            {
              name: 'Consulting',
              description:
                // "In today's fast-paced business environment, a robust and efficient technology infrastructure is crucial...",
                "In today's fast-paced business environment, a robust and efficient technology infrastructure is crucial for maintaining competitiveness and achieving business objectives. Our IT consulting services are designed to help organizations streamline their technology infrastructure, enabling them to operate more efficiently, reduce costs, and stay ahead of industry trends. This overview explores the key elements of our consulting approach, the benefits of streamlining technology, and the range of services we offer.",
              image: consultingImage,
              link: '/services/consulting',
            },
            {
              name: 'Artificial Intelligence',
              description:
                // 'Our AI services help businesses leverage the power of machine learning...',
                "Our AI services help businesses leverage the power of machine learning and automation to improve efficiency and make informed decisions. Whether it's developing custom AI models, implementing NLP, or integrating automation into your operations, we provide solutions tailored to your needs.",
              image: aiImage,
              link: '/services/artificial-intelligence',
            },
          ].map((service) => (
            <li className="service-item" key={service.name}>
              <div
                className="service-container"
                style={{ backgroundImage: `url(${service.image})` }}
              ></div>
              <div className="service-description">
                <h3>{service.name}</h3>
                <p>{service.description}</p>
                {/*Comment(Will be add thi later) <Link to={service.link} className="read-more-button">Read More</Link> */}
              </div>
            </li>
          ))}
        </ul>
        </div>
      </Container>
    </section>
  );
};

export default Services;
