import React, { useState, useEffect } from 'react';
import CookiePreferencesModal from './CookiePreferencesModal';
import './CookieConsentPopup.css';

const CookieConsentPopup = ({ show, onAccept, onDeny }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPreferencesVisible, setIsPreferencesVisible] = useState(false);

  useEffect(() => {
    // Check localStorage for previous consent decision
    const consent = localStorage.getItem('cookie-consent');
    if (!consent && show) {
      const timeout = setTimeout(() => {
        setIsModalVisible(true);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);

  const handleAccept = () => {
    onAccept();
    localStorage.setItem('cookie-consent', 'accepted');
    setIsModalVisible(false);
  };

  const handleDeny = () => {
    onDeny();
    localStorage.setItem('cookie-consent', 'denied');
    setIsModalVisible(false);
  };

  const handleManage = () => {
    setIsPreferencesVisible(true);
  };

  const closePreferencesModal = () => {
    setIsPreferencesVisible(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookie-consent', 'preferences-set');
    closePreferencesModal();
    setIsModalVisible(false);
  };

  if (!isModalVisible) return null;

  return (
    <>
      <div className="cookie-consent-popup">
        <h6 id='h6'><b>We value your privacy</b></h6>
        <div className="cookie-consent-content">
          <p>We use cookies to enhance your browsing experience. By accepting, you consent to the use of cookies.</p>
          <div className="cookie-consent-actions">
            <button className="cookie-btn accept" onClick={handleAccept}>Accept All</button>
            <button className="cookie-btn manage" onClick={handleManage}>Manage Preferences</button>
            <button className="cookie-btn deny" onClick={handleDeny}>Deny</button>
          </div>
        </div>
      </div>

      <CookiePreferencesModal
        isVisible={isPreferencesVisible}
        onClose={closePreferencesModal}
        onSavePreferences={handleSavePreferences}
      />
    </>
  );
};

export default CookieConsentPopup;
