import React, { useState } from 'react';
import './Contact.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faForward } from '@fortawesome/free-solid-svg-icons'; // Import the icon you need
import config from '../config'; // Adjust the import path as necessary
import { Form, Button } from 'react-bootstrap';
import { Card, Container, CardHeader } from 'reactstrap';
import contactImage from "../assets/img/Contact.jpg";

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [showDetailedInfo, setShowDetailedInfo] = useState(false); // State for toggle

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name.trim() || !email.trim() || !message.trim()) {
      alert('Please fill out all fields.');
      return;
    }
    try {
      const response = await axios.post(`${config.API_BASE_URL}/api/messages`, {
        name: name,
        email: email,
        message: message
      });
      console.log(response.data); // Log the response from the Flask backend
      if (response.status === 200) {
        alert('Message sent successfully!');
      }
      // Reset form fields after successful submission
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const handleReadMoreClick = () => {
    navigate('/contact-us-detailed'); // Navigate to ContactUsDetailed
  };

  const toggleDetailedInfo = () => {
    setShowDetailedInfo(!showDetailedInfo); // Toggle the state
  };

  return (
    <section id="contact" className="contact-section">

<div className="contact-image-container">
        <img src={contactImage} alt="Contact Us" className="contact-image" loading="lazy"  />
        <div className="contact-image-text">
          <h2>Contact Us</h2>
          <p>We Would Love To Hear From You.</p>
        </div>
      </div>

      <div className="contact-info">
        <h3>Contact Us</h3>
        <p>We'd love to hear from you!</p>
        <address>
          <strong>Our Office:</strong>
          <br />
          801, 8th floor, NDM 1
          <br />
          Netaji Subhash Place, Pitampura, New Delhi, Delhi 110034
          <br />
          <br />
          Email: <a href="mailto:info@excavateresearch.com">info@excavateresearch.com</a>
        </address>
      </div>
      {/* <div className="contact-content-new"> */}
      <Container>
      {/* <Card
            color="success"
            outline 
         className="text-center"> */}
        {/* <CardHeader tag="h2">
        Contact Us
    </CardHeader> */}
        <Form onSubmit={handleSubmit} style={{
      marginTop:"2.5%"}}>
          <Form.Group controlId="formName"  className="contact-content-new">
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          </Form.Group>
          <Form.Group controlId="formEmail"  className="contact-content-new">
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </Form.Group>
          <Form.Group controlId="formMessage"  className="contact-content-new">
            <Form.Control
              as="textarea"
              rows={3}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Message"
            />
          </Form.Group>
          <button className='button' type="submit"><span>Send Message</span></button>

        {/* Render either the toggle button or the navigation button based on condition */}
        {/* {showDetailedInfo ? (
          <button
            onClick={toggleDetailedInfo}
            className="solution-card-read-more-text read-more-cta text-uppercase position-relative pl-4 other-icon"
          >
            {showDetailedInfo ? 'Read Less' : 'Read More'}
          </button>
        ) : (
          <button className='button'>
          <span onClick={handleReadMoreClick}>
          Let's Connect
          </span>
          </button>
        )} */}
            
            </Form>

        {/* <p>Email: <a href="mailto:info@excavateresearch.com">info@excavateresearch.com</a></p> */}
      {/* </div> */}
      {/* </Card> */}
      </Container>
    </section>
  );
};

export default Contact;
