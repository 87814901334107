import React from 'react';
import './DataProcessing.css'; // Import the CSS for this page
import dataProcessingImage from '../assets/img/data_analyst_image.png'; // Add your image path here
import dataCleaningImage from '../assets/img/AI_Home1.webp'; // Add your image path here
import dataTransformationImage from '../assets/img/CheckingmarkImage.jpg'; // Add your image path here
import dataAggregationImage from '../assets/img/survey.jpg'; // Add your image path here
import dataAnalysisImage from '../assets/img/DataAnalystImage.jpg'; // Add your image path here
import dataVisualizationImage from '../assets/img/HI-and-AI.png'; // Add your image path here

const DataProcessing = () => {
    return (
      <div className="container data-processing-container mt-5" id="data-processing">
        <h1 className="text-center animate__animated animate__fadeInDown">Data Processing</h1>
        <img src={dataProcessingImage} alt="Data Processing" className="data-image animate__animated animate__zoomIn animate__delay-1s img-fluid shadow-lg" loading="lazy"  />
        
        <p className="lead text-center animate__animated animate__fadeInUp animate__delay-1s">
          At <strong>Excavate Research</strong>, we specialize in transforming raw data into meaningful insights. Our data processing services include:
        </p>
        
        <ul className="list-group text-center animate__animated animate__fadeInUp animate__delay-2s">
          <li className="list-group-item list-group-item-hover">Data Cleaning</li>
          <li className="list-group-item list-group-item-hover">Data Transformation</li>
          <li className="list-group-item list-group-item-hover">Data Aggregation</li>
          <li className="list-group-item list-group-item-hover">Data Analysis</li>
          <li className="list-group-item list-group-item-hover">Data Visualization</li>
        </ul>
        
        <div className="mt-5">
          <h3 className="text-center animate__animated animate__fadeInUp">Our services Explained</h3>

          {/* dataservice Items */}
          {[
            {
              image: dataCleaningImage,
              title: "1. Data Cleaning",
              description: "Data cleaning involves identifying and correcting errors or inconsistencies in the data. This step is crucial because dirty data can lead to inaccurate analysis and poor decision-making.",
              delay: 1
            },
            {
              image: dataTransformationImage,
              title: "2. Data Transformation",
              description: "Data transformation is the process of converting data into a suitable format for analysis. This may include aggregating data from different sources, changing formats, or applying calculations.",
              delay: 2
            },
            {
              image: dataAggregationImage,
              title: "3. Data Aggregation",
              description: "Data aggregation involves compiling data from multiple sources and presenting it in a summary format.",
              delay: 3
            },
            {
              image: dataAnalysisImage,
              title: "4. Data Analysis",
              description: "Our data analysis services involve using statistical methods and analytical tools to extract actionable insights from your data.",
              delay: 4
            },
            {
              image: dataVisualizationImage,
              title: "5. Data Visualization",
              description: "Data visualization is the graphical representation of information and data. By using visual elements like charts, graphs, and maps, we help clients understand complex data sets at a glance.",
              delay: 5
            },
          ].map((service, index) => (
            <div 
              key={index} 
              className={`dataservice animate__animated animate__fadeInUp animate__delay-${service.delay}s`} 
              style={{ opacity: 1 }} // Ensure it's fully visible when animation ends
            >
              <img src={service.image} alt={service.title} className="dataservice-image img-fluid shadow-sm" loading="lazy"  />
              <div className="dataservice-text">
                <h4>{service.title}</h4>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
};

export default DataProcessing;
