import React from 'react';
import './SoftwareDevelopmentExpertise.css'; // Link to the CSS file
import governmentImage from '../assets/img/hr4.png'; // Example image
import campaignImage from '../assets/img/hr2.png';

const SoftwareDevelopmentExpertise = () => {
  return (
    <div className="container software-development-expertise" id="software-development-expertise">
      <div className="row">
        <div className="col text-center animate__animated animate__fadeInDown">
          <h1>Software Development Expertise</h1>
          <p className="lead animate__animated animate__fadeInUp animate__delay-1s">
            At <strong>Excavate Research</strong>, we specialize in providing customized software solutions for government and large-scale organizations. Our development process focuses on creating innovative systems that address the unique needs of each client, enhancing efficiency and streamlining operations.
          </p>
        </div>
      </div>

      <div className="row mt-5 align-items-center animate__animated animate__fadeInLeft">
        <div className="col-md-6">
          <h2>Government Sector Solutions</h2>
          <p>
            We have extensive experience in developing software systems tailored to meet the specific requirements of government sectors. One of our most notable achievements includes the creation of a Fund Dispersal Management System for the Haryana Panchayat. This system efficiently manages the allocation and tracking of funds across various projects, ensuring transparency and accountability at every level.
          </p>
        </div>
        <div className="col-md-6">
          <img src={governmentImage} alt="Government Solutions" className="img-fluid rounded shadow animate__animated animate__zoomIn" loading="lazy"  />
        </div>
      </div>

      <div className="row mt-5 align-items-center animate__animated animate__fadeInRight">
        <div>
          <h3>Social Media Warriors: Campaign Management App</h3>
          <img src={campaignImage} alt="Campaign Management App" className="img-fluid rounded shadow animate__animated animate__zoomIn animate__delay-1s" loading="lazy"  /><br /><br />
          <p>
            We also developed Social Media Warriors, an Android application designed for managing large-scale campaigns. This app integrates key features of popular platforms like Facebook and WhatsApp, enabling seamless communication within the same operational block. It also includes the following modules:
          </p>
          
          <ul className="list-group list-group-flush">
            <li className="list-group-item">Voter List Management: Streamlines voter data for quick access and updates.</li>
            <li className="list-group-item">Campaign Strategy & Cosplaying Management: Ensures effective role management for campaign events.</li>
            <li className="list-group-item">Assignment Management Module: Assign tasks to campaign workers and track progress efficiently.</li>
            <li className="list-group-item">Request Data Module: Handle requests and queries with a responsive system.</li>
            <li className="list-group-item">Points System: Workers are rewarded based on task completion and performance.</li>
            <li className="list-group-item">GPS Tagging: Allows complaints and issues to be geotagged, ensuring quick response and resolution.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDevelopmentExpertise;
