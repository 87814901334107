import React from 'react';
import './Consulting.css'; // Import the CSS file
import AgileDevOps from '../components/AgileDevOpsDiagram'
import BusinessStrategyImg from '../assets/img/Business Strategy Consulting.jpg'
import TechnologyAdvisoryImg from '../assets/img/TechnologyAdvisoryImg.jpg'
import OperationalEfficiencyImprovementImg from '../assets/img/Operational Efficiency Improvement.jpg'
import ProjectManagementImg from '../assets/img/Project Management.jpg'
import ChangeManagementImg from '../assets/img/Change Management.jpg'

const Consulting = () => {
  return (
    <section id="consulting">
      <div className="consulting-container">
        <h2>Expert Consulting Services</h2>
        <div className="services-list">

          <p className="intro-text">
            Unlock your business's potential with our expert consulting services. From strategic planning to operational optimization, we provide tailored solutions designed to drive growth and success.
            <br></br>
            Whether you're aiming to enhance your technology infrastructure, improve efficiency, or navigate complex change, our experienced team delivers actionable insights and hands-on support every step of the way.
          </p>
          <div className="service-card-new">
            <div className="card-content">
              <img src={BusinessStrategyImg} alt="Business Strategy" loading="lazy"  />
              <div className="text-overlay">
                <h3>Business Strategy Consulting</h3>
                <p>Craft growth strategies that align with your vision and market demands to propel your business forward.</p>
              </div>
            </div>
          </div>
          <div className="service-card-new">
            <div className="card-content">
              <img src={TechnologyAdvisoryImg} alt="Technology Advisory" loading="lazy"  />
              <div className="text-overlay">
            <h3>Technology Advisory</h3>
            <p>Leverage the latest technologies to streamline operations, enhance innovation, and achieve a competitive edge.</p>
          </div>
            </div>
          </div>
          <div className="service-card-new">
            <div className="card-content">
              <img src={OperationalEfficiencyImprovementImg} alt="Operational Efficiency Improvement" loading="lazy"  />
              <div className="text-overlay">
            <h3>Operational Efficiency Improvement</h3>
            <p>Optimize processes, reduce costs, and improve performance across all operational aspects.</p>
          </div>
            </div>
          </div>
          <div className="service-card-new">
            <div className="card-content">
              <img src={ProjectManagementImg} alt="Project Management" loading="lazy"  />
              <div className="text-overlay">
            <h3>Project Management</h3>
            <p>Ensure successful project execution from inception to completion, on time and within budget.</p>
          </div>
            </div>
          </div>
          <div className="service-card-new">
            <div className="card-content">
              <img src={ChangeManagementImg} alt="Change Management" loading="lazy"  />
              <div className="text-overlay">
            <h3>Change Management</h3>
            <p>Implement organizational change smoothly, empowering your team and minimizing disruption.</p>
          </div>
            </div>
          </div>
        </div>
        <AgileDevOps />
        <p className="cta-text">Let us help you build a smarter, more agile business. Contact us today to learn more.</p>
        <a href="/contact" className="cta-button">Get in Touch</a>
      </div>
    </section>
  );
};

export default Consulting;
