import React from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Slideshow from './Slideshow';
import Home from './Home';
import { Container } from 'reactstrap';

const ServiceDetail = () => {
  const { serviceName } = useParams();

  // Normalize serviceName to ensure correct matching
  const normalizedServiceName = serviceName.toLowerCase(); 
  // Data for each service - you can replace this with data from a server or other source
  const serviceData = {
    'data-analysis': {
      title: 'Data Analysis',
      content:
        'Detailed description for Data Analysis. This section can contain as much content as needed to fully describe the service.',
    },
    charting: {
      title: 'Charting',
      content: 'Detailed description for Charting.',
    },
    dashboards: {
      title: 'Dashboards',
      content: 'Detailed description for Dashboards.',
    },
    scripting: {
      title: 'Scripting',
      content: 'Detailed description for Scripting.',
    },
    consulting: {
      title: 'Consulting',
      content: 'Detailed description for Consulting.',
    },
    'artificial-intelligence': {
      title: 'Artificial Intelligence',
      content: 'Detailed description for Artificial Intelligence.',
    },
  };
  const service = serviceData[normalizedServiceName]; // Use normalized key

//   const service = serviceData[serviceName];

  if (!service) {
    return <div>Service not found</div>;
  }

  return (
    <section id="service-detail" style={{backgroundColor:'white'}}>
      
    {/* <Header /> */}
    <Home />
    <Container>
      <h2>{service.title}</h2>
      <p>{service.content}</p>
      </Container>
    </section>
  );
};

export default ServiceDetail;
