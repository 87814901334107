import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Mission from './components/Mission';
import Services from './components/Services';
import Team from './components/Team';
import Contact from './components/Contact';
import ContactUsDetailed from './components/ContactUsDetailed';
import ServiceDetail from './components/ServiceDetail';
import 'bootstrap/dist/css/bootstrap.min.css';
import ClientsPage from './components/ClientsPage';
import Overview from './components/Overview';
import DataAnalysis from './components/DataAnalysis'; // Separate component for Data Analysis
import Charting from './components/Charting'; // Separate component for Charting
import Dashboards from './components/Dashboards'; // Separate component for Dashboards
import AI from './components/AI'; // Separate component for Artificial Intelligence
import Scripting from './components/Scripting';
import Consulting from './components/Consulting';
import DataExtraction from './components/DataExtraction'; // Updated path
import PipelineDevelopment from './components/PipelineDevelopment';
import DataWarehouseDesign from './components/DataWarehouseDesign';
import DataGovernance from './components/DataGovernance';
import CloudDataMigration from './components/CloudDataMigration';
import DataIntegration from './components/DataIntegration';
import Contribution from './components/Contribution';
import AgileDevOpsDiagram from './components/AgileDevOpsDiagram'
import SoftwareDevelopmentExpertise from './components/SoftwareDevelopmentExpertise'
import DataProcessing from './components/DataProcessing';

const MainContent = () => {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Header />
      {/* <AgileDevOpsDiagram /> */}
      <main>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<><Mission /><Services /><Team /><Contact /></>} />
          </Route>
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/data-analysis" element={<DataAnalysis />} />
          <Route path="/services/charting" element={<Charting />} />
          <Route path="/services/data-visualization" element={<Dashboards />} />
          <Route path="/services/market-research/survey-scripting" element={<Scripting />} />
          <Route path="/services/market-research/data-processing" element={<DataProcessing />} />
          <Route path="/services/scripting" element={<Scripting />} />
          <Route path="/services/agile-devops" element={<AgileDevOpsDiagram />} />
          <Route path="/services/ai" element={<AI />} />
          <Route path="/services/consulting" element={<Consulting />} />
          <Route path="/services/software-development-expertise" element={<SoftwareDevelopmentExpertise />} />
          <Route path="/services/cloud-computing/data-extraction" element={<DataExtraction />} />
          <Route path="/services/cloud-computing/pipeline-development" element={<PipelineDevelopment />} />
          <Route path="/services/cloud-computing/data-warehouse" element={<DataWarehouseDesign />} />
          <Route path="/services/cloud-computing/data-integration" element={<DataIntegration />} />
          <Route path="/services/cloud-computing/data-governance" element={<DataGovernance />} />
          <Route path="/services/cloud-computing/cloud-migration" element={<CloudDataMigration />} />
          <Route path="/services/:serviceName" element={<ServiceDetail />} />
          <Route path="/career" element={<ContactUsDetailed />} />
          <Route path="/about/overview" element={<Overview />} />
          <Route path="/about/fun-of-era" element={<Team />} />
          <Route path="/about/csr-initiative" element={<Contribution />} />
        </Routes>
      </main>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <MainContent />
      <Footer />
    </Router>
  );
};

export default App;
