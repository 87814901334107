import React from 'react';
import './Mission.css';
import mission from '../assets/img/mission.jpg';

const Mission = () => {
  return (
    <section id="mission">
      <div className="mission-section">
        <div className="mission-content">
          <h2>Our Mission</h2>
          <p>
            At Excavate Research & Analysis Pvt. Ltd., our mission is to empower organizations with actionable insights derived from data. We are committed to delivering comprehensive data analysis solutions that drive informed decision-making and foster business success.
          </p>
          <p>
            Through innovative approaches and advanced technologies, we strive to unlock the full potential of data, enabling our clients to navigate complexities, seize opportunities, and stay ahead in today's dynamic business landscape.
          </p>
        </div>
        <div className="mission-image">
          <img src={mission} alt="Our Mission" loading="lazy"  />
        </div>
      </div>
    </section>
  );
}

export default Mission;
