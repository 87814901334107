import React from 'react';
import './DataWarehouseDesign.css'; // Import your CSS file for styling

const DataWarehouseDesign = () => {
  return (
    <div className="data-warehouse-design">
      <div className="data-warehouse-intro">
        <h2>Data Warehouse Design</h2>
        <p>Create comprehensive data warehouses that support efficient data storage, retrieval, and analysis.</p>
      </div>
      <section className="data-warehouse-details">
        <div className="data-warehouse-point">
          <h3>Structured Data Architecture</h3>
          <p>Design scalable architectures that organize data effectively for rapid access and query performance.</p>
        </div>
        <div className="data-warehouse-point">
          <h3>Optimized Data Storage</h3>
          <p>Implement storage solutions that maximize data retrieval speed and minimize costs.</p>
        </div>
        <div className="data-warehouse-point">
          <h3>Advanced Data Integration</h3>
          <p>Integrate data from various sources to create a unified view for analysis and reporting.</p>
        </div>
        <div className="data-warehouse-point">
          <h3>Scalable Solutions</h3>
          <p>Build systems that can grow with your data needs, ensuring long-term efficiency.</p>
        </div>
      </section>
    </div>
  );
}

export default DataWarehouseDesign;
